
	import { Vue, Component } from 'vue-property-decorator';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
	import Modal from '@pixcap/ui-library/components/Modal.vue';
	import LoadingState from '@pixcap/ui-library/components/LoadingState.vue';
	import { mutationsWrapper as ReadyPlayerMeMutations, actionsWrapper as ReadyPlayerMeActions } from '@pixcap/ui-core/store/readyplayerme';
	import { READY_PLAYER_ME_URL } from '@pixcap/ui-core/constants/app.constants';

	@Component({
		name: 'ReadyPlayerMeModal',
		components: {
			Modal,
			LoadingState,
		},
	})
	export default class ReadyPlayerMeModal extends Vue {
		$refs: {
			rpmFrame: HTMLIFrameElement;
		};

		isLoading = false;

		get RPM_IFRAME_SRC() {
			return `${READY_PLAYER_ME_URL}?frameApi`;
		}

		handleCloseModal() {
			ReadyPlayerMeMutations.setShowReadyPlayerMeModal(this.$store, null);
		}

		private async _subscribe(event) {
			try {
				let json;
				try {
					json = JSON.parse(event.data);
				} catch (error) {}

				if (json?.source !== 'readyplayerme') {
					return;
				}

				// Susbribe to all events sent from Ready Player Me once frame is ready
				if (json.eventName === 'v1.frame.ready') {
					this.$refs.rpmFrame.contentWindow.postMessage(
						JSON.stringify({
							target: 'readyplayerme',
							type: 'subscribe',
							eventName: 'v1.**',
						}),
						'*'
					);
				}

				// Get avatar GLB URL
				if (json.eventName === 'v1.avatar.exported') {
					try {
						const { avatarId, url } = json.data;
						this.isLoading = true;
						const fileId = await ReadyPlayerMeActions.saveRpmAvatar(this.$store, avatarId, url);
						if (fileId) {
							this.$emit('onAvatarCreated', { file: fileId, meta: JSON.stringify({ rpm: true }) });
						} else {
							this.$notify({
								title: 'Encountered problem saving character',
								text: 'Please try again',
								type: NotificationType.WARN,
								duration: 3000,
							});
						}
						this.handleCloseModal();
					} finally {
						this.isLoading = false;
					}
				}
			} finally {
			}
		}

		created() {
			window.addEventListener('message', this._subscribe);
			document.addEventListener('message', this._subscribe);
		}

		beforeDestroy() {
			window.removeEventListener('message', this._subscribe);
			document.removeEventListener('message', this._subscribe);
		}
	}
