import { getImageDimensions } from '@pixcap/ui-core/utils/images/getImageDimensions';

export async function getCroppableImageState(backgroundUrl: string, pageSize: { width: number; height: number }): Promise<any> {
	const imageDimensions = await getImageDimensions(backgroundUrl);
	return {
		url: backgroundUrl,
		imageSize: {
			w: imageDimensions.width,
			h: imageDimensions.height,
		},
		cropBox: scaleToCover(imageDimensions, pageSize),
	};
}

export function scaleToCover(imageBox: { width: number; height: number }, frameBox: { width: number; height: number }) {
	// Calculate scale factors
	const scaleX = frameBox.width / imageBox.width;
	const scaleY = frameBox.height / imageBox.height;

	// Choose the larger scale factor
	const scale = Math.max(scaleX, scaleY);

	// Calculate the new dimensions of the image box
	const newWidth = imageBox.width * scale;
	const newHeight = imageBox.height * scale;

	// Calculate the offset to center the image box
	const offsetX = (frameBox.width - newWidth) / 2;
	const offsetY = (frameBox.height - newHeight) / 2;

	return {
		w: newWidth,
		h: newHeight,
		x: offsetX,
		y: offsetY,
	};
}
