import { CornerDirection, EdgeDirection } from '@pixcap/ui-core/models/store/resizeable.interface';

export function getResizeBounding(
	bounding: { width: number; height: number; top: number; left: number },
	direction: CornerDirection | EdgeDirection,
	aspectRatio: number,
	xDelta: number,
	yDelta: number
): { width: number; height: number; top: number; left: number } {
	const newBounding: any = {};
	const isRightDirection = direction.indexOf('right') >= 0;
	const isLeftDirection = direction.indexOf('left') >= 0;
	const isTopDirection = direction.indexOf('top') >= 0;

	if (isLeftDirection || isRightDirection) {
		newBounding.width = bounding.width + (isLeftDirection ? -xDelta : xDelta);
		newBounding.height = newBounding.width / aspectRatio;
	}
	if (direction == EdgeDirection.TOP || direction == EdgeDirection.BOTTOM) {
		newBounding.height = bounding.height + (isTopDirection ? -yDelta : yDelta);
		newBounding.width = newBounding.height * aspectRatio;
	}
	if (direction == EdgeDirection.TOP) {
		newBounding.top = bounding.top + yDelta;
	} else if (isTopDirection) {
		newBounding.top = bounding.top + (bounding.height - newBounding.height);
	}
	if (isLeftDirection) {
		newBounding.left = bounding.left + xDelta;
	}
	return newBounding;
}
