export function validateEmail(email) {
	const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	return re.test(String(email).toLowerCase());
}

//(?=(?:.*\d){3})
export function validatePassword(password) {
	const re = /^[^\s]{8,99}$/;
	return re.test(String(password));
}
