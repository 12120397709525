
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { mapGetters } from 'vuex';
	import { NAMESPACE as USER_NAMESPACE, GetterTypes as UserGettersTypes, Getters as UserGetters } from '@pixcap/ui-core/models/store/user.interface';
	import { actionsWrapper as UserActions } from '@pixcap/ui-core/store/user';
	import Button from '@pixcap/ui-library/components/Button.vue';

	@Component({
		name: 'UpgradeButton',
		components: {
			Button,
		},
		computed: {
			...mapGetters(USER_NAMESPACE, {
				isUnsubscribedUser: UserGettersTypes.IS_UNSUBSCRIBED_USER,
				isCanceledSubscription: UserGettersTypes.IS_CANCELED_SUBSCRIPTION,
				isSubscribedWithStripe: UserGettersTypes.IS_SUBSCRIBED_WITH_STRIPE,
			}),
		},
	})
	export default class UpgradeButton extends Vue {
		@Prop() readonly size: string | undefined;
		isUnsubscribedUser: ReturnType<UserGetters[UserGettersTypes.IS_UNSUBSCRIBED_USER]>;
		isCanceledSubscription: ReturnType<UserGetters[UserGettersTypes.IS_CANCELED_SUBSCRIPTION]>;
		isSubscribedWithStripe: ReturnType<UserGetters[UserGettersTypes.IS_SUBSCRIBED_WITH_STRIPE]>;

		isLoading = false;

		async renewPlan() {
			this.isLoading = true;
			await UserActions.renewSubscription(this.$store);
			this.isLoading = false;
		}
	}
