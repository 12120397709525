
	import { Component, Mixins, Watch } from 'vue-property-decorator';
	import UpgradeSubscriptionMixin from '@pixcap/ui-core/components/payment/UpgradeSubscriptionMixin';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user/wrapper';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import IconLockSimple from '@pixcap/ui-library/components/Icons/IconLockSimple.vue';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import { USAGE_LIMIT } from '@pixcap/ui-core/constants/subscription.constants';
	import { SUBSCRIPTION_UPGRADE_CASE } from '@pixcap/ui-core/models/store/user.interface';

	@Component({
		name: 'VideoDownloadTopUpModal',
		components: {
			CaptionText,
			IconCheck,
			IconLockSimple,
			IconClose,
			Button,
			BodyText,
			HeadingText,
			BaseModal,
		},
	})
	export default class VideoDownloadTopUpModal extends Mixins(UpgradeSubscriptionMixin) {
		USAGE_LIMIT = USAGE_LIMIT;
		selectedBundleIndex = 0;

		$refs: {
			planHighlightMobile: HTMLInputElement;
		};

		@Watch('currentBundle', { immediate: true })
		onSelectedPeriodChange() {
			this.$nextTick(() => {
				if (this.$refs.planHighlightMobile) {
					switch (this.currentBundle) {
						case this.limitBundles[0]:
							this.$refs.planHighlightMobile.style.left = '4px';
							break;
						case this.limitBundles[1]:
							this.$refs.planHighlightMobile.style.left = 'calc(100%/3)';
							break;
						case this.limitBundles[2]:
							this.$refs.planHighlightMobile.style.left = 'calc(100% - calc(100%/3) - 4px)';
							break;
					}
				}
			});
		}

		get title() {
			const triggerCase = this.topUpLimitsModalState.triggerCase;
			switch (triggerCase) {
				case SUBSCRIPTION_UPGRADE_CASE.HIT_USAGE_LIMIT_HQ_VIDEO_DOWNLOAD:
					return `Monthly limit reached (${this.USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.ELITE}). Get more video downloads that suit your needs.`;
				case SUBSCRIPTION_UPGRADE_CASE.GET_MORE_HQ_VIDEO_DOWNLOAD:
				default:
					return `Get more video downloads <br> that suit your needs.`;
			}
		}

		get limitBundles() {
			return this.getPricingForUser.VIDEO_LIMITS.sort((a, b) => b.QUANTITY - a.QUANTITY);
		}

		get currentBundle() {
			return this.limitBundles[this.selectedBundleIndex];
		}

		get currencyPrefix() {
			return this.getPricingForUser.CURRENCY_PREFIX;
		}

		handleSelectBundle(index: number) {
			this.selectedBundleIndex = index;
		}

		async handlePurchase() {
			await this.openToppedCreditsPaymentPortal({ quantity: this.currentBundle.QUANTITY, openPaymentMethodModal: true });
			this.handleCloseModal();
		}

		handleCloseModal() {
			UserMutations.setTopUpVideoLimitsModalState(this.$store, null);
		}
	}
