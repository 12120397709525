
	import { Component, Vue } from 'vue-property-decorator';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import IconMagicWand from '@pixcap/ui-library/components/Icons/IconMagicWand.vue';
	@Component({
		name: 'AIImagesCarousel',
		components: {
			CaptionText,
			IconMagicWand,
		},
		computed: {},
	})
	export default class AIImagesCarousel extends Vue {
		activeImage = 0;
		images = [
			`${_PIXCAP_ENV.ROOT_URL}/cdn/app/pricing/ai-image-1.webp`,
			`${_PIXCAP_ENV.ROOT_URL}/cdn/app/pricing/ai-image-2.webp`,
			`${_PIXCAP_ENV.ROOT_URL}/cdn/app/pricing/ai-image-3.webp`,
		];

		_imageChangeInterval = null;

		mounted() {
			setInterval(() => {
				const activeImage = this.activeImage + 1;
				if (activeImage >= this.images.length) this.activeImage = 0;
				else this.activeImage = activeImage;
			}, 3500);
		}

		beforeDestroy() {
			clearInterval(this._imageChangeInterval);
		}
	}
