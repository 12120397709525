
	import { Component, Mixins } from 'vue-property-decorator';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { PaymentChannel, SUBSCRIPTION } from '@pixcap/ui-core/models/store/user.interface';
	import UpgradeSubscriptionMixin from '@pixcap/ui-core/components/payment/UpgradeSubscriptionMixin';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconCircleNotch from '@pixcap/ui-library/components/Icons/IconCircleNotch.vue';
	import IconCreditCard from '@pixcap/ui-library/components/Icons/IconCreditCard.vue';
	import IconPaypal from '@pixcap/ui-library/components/PaymentIcons/IconPaypal.vue';
	import IconMasterCard from '@pixcap/ui-library/components/PaymentIcons/IconMasterCard.vue';
	import IconUnionPay from '@pixcap/ui-library/components/PaymentIcons/IconUnionPay.vue';
	import IconVisa from '@pixcap/ui-library/components/PaymentIcons/IconVisa.vue';

	@Component({
		name: 'SubscriptionPaymentMethodModal',
		components: {
			BaseModal,
			IconPaypal,
			IconCircleNotch,
			IconCreditCard,
			IconMasterCard,
			IconUnionPay,
			IconVisa,
			BodyText,
		},
		computed: {},
	})
	export default class SubscriptionPaymentMethodModal extends Mixins(UpgradeSubscriptionMixin) {
		get isMonthlyPlan() {
			return [SUBSCRIPTION.PRO_MONTHLY, SUBSCRIPTION.ELITE_MONTHLY].includes(this.selectedSubscriptionForPayment);
		}

		async payWithCreditCard() {
			await this.openSubscriptionPaymentPortal({ newSubscription: this.selectedSubscriptionForPayment, channel: this.payWithCreditCardChannel });
			//this.handleClose();
		}
		async payWithPaypal() {
			await this.openSubscriptionPaymentPortal({ newSubscription: this.selectedSubscriptionForPayment, channel: PaymentChannel.PAYPAL });
			//this.handleClose();
		}
		handleClose() {
			UserMutations.setSelectedSubscriptionForPayment(this.$store, null);
		}

		created() {
			this.handleCloseMethod = this.handleClose.bind(this);
		}
	}
