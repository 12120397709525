export async function cropImageUsingCropBox(
	imageUrl: string,
	imageBox: { width: number; height: number },
	cropBox: { x: number; y: number; w: number; h: number },
	returnAsBlob = false
): Promise<any> {
	return new Promise((resolve, reject) => {
		const { width, height } = imageBox;
		const { w: cropWidth, h: cropHeight, x: cropX, y: cropY } = cropBox;
		const image = new Image();
		image.src = imageUrl;
		//image.crossOrigin = 'anonymous';
		image.onload = () => {
			try {
				const canvas = document.createElement('canvas');
				canvas.width = width;
				canvas.height = height;
				const ctx = canvas.getContext('2d');
				ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight);
				canvas.toBlob((blob) => {
					if (blob) {
						if (returnAsBlob) resolve(blob);
						else resolve(URL.createObjectURL(blob));
					} else reject(new Error('Failed to create image blob.'));
				}, 'image/png');
			} catch (error) {
				reject(error);
			}
		};
		image.onerror = () => {
			reject(new Error('Error loading the image.'));
		};
	});
}
