export enum TRIAL_MODAL_STATE {
	PRO_FEATURE = 'PRO_FEATURE',
	SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS',
	CANCEL_PLAN = 'CANCEL_PLAN',
	LEAVING_REASON = 'LEAVING_REASON',
	LEAVING_MESSAGE = 'LEAVING_MESSAGE',
	SWITCH_PLAN = 'SWITCH_PLAN',
}

export const TRIAL_MODAL_INFO = {
	[TRIAL_MODAL_STATE.PRO_FEATURE]: {
		title: 'Upgrade your 3D designs with Pixcap Pro',
		description:
			'Unleash your creativity using thousand of curated assets, scenes and advance features! Deliver your designs with high-quality resolutions and have unlimited exports.',
		isBack: false,
	},
	[TRIAL_MODAL_STATE.SUBSCRIPTION_DETAILS]: {
		title: 'Subscription details',
		description: 'Try the 7-day free trial and enjoy different PRO perks. Don’t worry, we’ll notify you in your email twice before your trial ends.',
		isBack: true,
	},
	[TRIAL_MODAL_STATE.CANCEL_PLAN]: {
		title: 'You’ll lose all the Pixcap premium features once your current plan expires',
		description: '',
		isBack: false,
	},
	[TRIAL_MODAL_STATE.LEAVING_REASON]: {
		title: 'We’d like to know why you are leaving',
		description: '',
		isBack: false,
	},
	[TRIAL_MODAL_STATE.LEAVING_MESSAGE]: {
		title: 'We’re sad to see you go',
		description: 'Share your experience while using Pixcap Pro subscriptions and let us know why you decided to cancel it.',
		isBack: false,
	},
	[TRIAL_MODAL_STATE.SWITCH_PLAN]: {
		title: 'Switch to yearly plan and save more!',
		description: 'Enjoy more than 35% off in your annual plan!',
		isBack: false,
	},
};

export const ANNUAL_PLAN_MODAL_INFO = {
	title: 'Keep yearly plan and save more!',
	text: '',
};

export const TRIAL_PRO_FEATURES = [
	'Access to over 10,000 premium 3D models, scenes, characters, poses and animations (‘Pro’ assets)',
	'Early access to new collections',
	'Export Videos and Images with no watermark',
];

export const LEAVE_REASON = [
	'It’s too expensive',
	'I need more features',
	'I found another similar website',
	'I don’t use it that often',
	'There are not many quality resources',
];
