var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseModal',{attrs:{"class-name":"payment-status-modal","allowOverflow":true,"show-close-button":true},on:{"onClose":_vm.handleCloseModal}},[_c('img',{staticClass:"payment-image",attrs:{"src":_vm.imgUrl}}),_vm._v(" "),_c('div',{staticClass:"modal-content"},[(_vm.isBuyItemEvent)?[_c('HeadingText',{attrs:{"weight":"bold","size":"small"}},[_vm._v(" Your purchased is completed! ")])]:[_c('HeadingText',{attrs:{"weight":"bold","size":"small"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.isTopUpEvent
						? _vm.$t
							? _vm.$t('payment_status_modal.title.topped_up', { topup_quantity: _vm.topUpQuantity })
							: `You have topped up ${_vm.topUpQuantity} video limits`
						: _vm.isUpdatedSubscription
						? _vm.$t
							? _vm.$t('payment_status_modal.title.updated_subscription', { subscription_type: _vm.subscriptionType })
							: `You’re ${_vm.subscriptionType} now!`
						: _vm.$t
						? _vm.$t('payment_status_modal.title.subscribed_to', { subscription_type: _vm.subscriptionType })
						: `You've subscribed to ${_vm.subscriptionType}!`)+"\n\t\t\t")])],_vm._v(" "),_c('BodyText',{staticClass:"description-text",attrs:{"tagName":"p"}},[_vm._v("\n\t\t\t"+_vm._s(_vm.paymentStatusDescription)+"\n\t\t")])],2),_vm._v(" "),_c('Button',{staticClass:"modal-button",nativeOn:{"click":function($event){return _vm.handleCloseModal.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t ? _vm.$t('payment_status_modal.cta.confirm') : `Let's go`))]),_vm._v(" "),(_vm.isUpdatedSubscription && _vm.isStripeSubscription && (!_vm.isTopUpEvent || !_vm.isBuyItemEvent))?_c('Button',{staticClass:"modal-button",attrs:{"color":"neutral","isLoading":_vm.isLoading,"variant":"outline"},nativeOn:{"click":function($event){return _vm.manageSubscription.apply(null, arguments)}}},[_vm._v("\n\t\t"+_vm._s(_vm.$t ? _vm.$t('payment_status_modal.cta.manage_subscription') : `Manage subscription`)+"\n\t")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }