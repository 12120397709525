
	import { Component, Mixins, Watch } from 'vue-property-decorator';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { SUBSCRIPTION, SUBSCRIPTION_TYPE, SUBSCRIPTION_UPGRADE_CASE, UPGRADE_PLAN_TRIGGER_TYPE } from '@pixcap/ui-core/models/store/user.interface';
	import { USAGE_LIMIT } from '@pixcap/ui-core/constants/subscription.constants';
	import UpgradeSubscriptionMixin from '@pixcap/ui-core/components/payment/UpgradeSubscriptionMixin';
	import BaseModal from '@pixcap/ui-library/components/BaseModal.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';

	/// icons list
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import IconCopy from '@pixcap/ui-library/components/Icons/IconCopy.vue';
	import IconLockSimple from '@pixcap/ui-library/components/Icons/IconLockSimple.vue';
	@Component({
		name: 'UpgradeMemberModal',
		components: {
			IconCopy,
			IconLockSimple,
			BaseModal,
			BodyText,
			HeadingText,
			CaptionText,
			Button,
			IconCheck,
			IconClose,
		},
	})
	export default class UpgradeMemberModal extends Mixins(UpgradeSubscriptionMixin) {
		isProPlanSelected = true;
		UPGRADE_PLAN_TRIGGER_TYPE = UPGRADE_PLAN_TRIGGER_TYPE;
		USAGE_LIMIT = USAGE_LIMIT;
		SUBSCRIPTION_TYPE = SUBSCRIPTION_TYPE;

		$refs: {
			periodHighlight: HTMLInputElement;
			planHighlight: HTMLInputElement;
			planHighlightMobile: HTMLInputElement;
		};

		@Watch('monthlyOrQuarterlyOn', { immediate: true })
		onSelectedPeriodChange() {
			this.$nextTick(() => {
				if (this.$refs.periodHighlight) {
					switch (this.monthlyOrQuarterlyOn) {
						case true:
							this.$refs.periodHighlight.style.left = '3px';
							break;
						case false:
							this.$refs.periodHighlight.style.left = 'calc(100%/2 - 3px) ';
							break;
					}
				}
			});
		}

		@Watch('isProPlanSelected', { immediate: true })
		onSelectedPlanChange() {
			this.$nextTick(() => {
				if (this.$refs.planHighlight) {
					switch (this.isProPlanSelected) {
						case true:
							this.$refs.planHighlight.style.left = '3px';
							break;
						case false:
							this.$refs.planHighlight.style.left = 'calc(100%/2 - 3px) ';
							break;
					}
				}
				if (this.$refs.planHighlightMobile) {
					switch (this.isProPlanSelected) {
						case true:
							this.$refs.planHighlightMobile.style.left = '3px';
							break;
						case false:
							this.$refs.planHighlightMobile.style.left = 'calc(100%/2 - 3px) ';
							break;
					}
				}
			});
		}

		get UPGRADE_FEATURE_SERVICES() {
			return {
				[SUBSCRIPTION_TYPE.PROFESSIONAL]: [
					{
						description: this.$t ? this.$t('upgrade_modal.features.access_premium_designs') : `Access to all PREMIUM designs`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.ACCESS_PREMIUM_PROJECT,
					},
					{
						description: this.$t
							? this.$t('upgrade_modal.features.default_quality_downloads', {
									number: USAGE_LIMIT.DEFAULT_DOWNLOAD.PRO === null ? this.$t('upgrade_modal.features.unlimited') : USAGE_LIMIT.DEFAULT_DOWNLOAD.PRO,
							  })
							: `${USAGE_LIMIT.DEFAULT_DOWNLOAD.PRO === null ? 'Unlimited' : USAGE_LIMIT.DEFAULT_DOWNLOAD.PRO} default-quality downloads`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_DEFAULT_DOWNLOAD,
						hiddenByDefault: true,
					},
					{
						description: this.$t
							? this.$t('upgrade_modal.features.hq_image_downloads', {
									number:
										USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.PRO === null ? this.$t('upgrade_modal.features.unlimited') : USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.PRO,
							  })
							: `${USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.PRO === null ? 'Unlimited' : USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.PRO} High-quality images per month`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_IMAGE_DOWNLOAD,
					},
					{
						description: this.$t
							? this.$t('upgrade_modal.features.hq_video_downloads', {
									number:
										USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.PRO === null ? this.$t('upgrade_modal.features.unlimited') : USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.PRO,
							  })
							: `${USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.PRO === null ? 'Unlimited' : USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.PRO} High-quality videos per month`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_VIDEO_DOWNLOAD,
					},
					{
						description: this.$t
							? this.$t('upgrade_modal.features.ai_usage', {
									number: USAGE_LIMIT.AI_USAGE.PRO === null ? this.$t('upgrade_modal.features.unlimited') : USAGE_LIMIT.AI_USAGE.PRO,
							  })
							: `${USAGE_LIMIT.AI_USAGE.PRO === null ? 'Unlimited' : USAGE_LIMIT.AI_USAGE.PRO} AI generations per month`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.AI_STYLIST_EXCEED_LIMIT,
						hiddenByDefault: true,
					},
					{
						description: this.$t ? this.$t('upgrade_modal.features.exclusive_materials') : `Exclusive presets & materials`,
						featureType: null,
					},
					{
						description: this.$t ? this.$t('upgrade_modal.features.exclusive_character_poses') : `Exclusive character poses`,
						featureType: null,
					},
					{
						description: this.$t ? this.$t('upgrade_modal.features.commercial_license') : `Commercial license`,
						featureType: null,
					},
					{
						description: this.$t ? this.$t('upgrade_modal.features.file_3d_export') : `3D file exports`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.GLB_DOWNLOAD,
						notIncluded: true,
					},
				],
				[SUBSCRIPTION_TYPE.ELITE]: [
					{
						description: this.$t ? this.$t('upgrade_modal.features.access_premium_designs') : `Access to all PREMIUM designs`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.ACCESS_PREMIUM_PROJECT,
					},
					{
						description: this.$t
							? this.$t('upgrade_modal.features.default_quality_downloads', {
									number:
										USAGE_LIMIT.DEFAULT_DOWNLOAD.ELITE === null ? this.$t('upgrade_modal.features.unlimited') : USAGE_LIMIT.DEFAULT_DOWNLOAD.ELITE,
							  })
							: `${USAGE_LIMIT.DEFAULT_DOWNLOAD.ELITE === null ? 'Unlimited' : USAGE_LIMIT.DEFAULT_DOWNLOAD.ELITE} default-quality downloads`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_DEFAULT_DOWNLOAD,
						hiddenByDefault: true,
					},
					{
						description: this.$t
							? this.$t('upgrade_modal.features.hq_image_downloads', {
									number:
										USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.ELITE === null ? this.$t('upgrade_modal.features.unlimited') : USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.ELITE,
							  })
							: `${USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.ELITE === null ? 'Unlimited' : USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.ELITE} High-quality images`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_IMAGE_DOWNLOAD,
					},
					{
						description: this.$t
							? this.$t('upgrade_modal.features.hq_video_downloads', {
									number:
										USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.ELITE === null ? this.$t('upgrade_modal.features.unlimited') : USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.ELITE,
							  })
							: `${USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.ELITE === null ? 'Unlimited' : USAGE_LIMIT.HQ_VIDEO_DOWNLOAD.ELITE} High-quality videos per month`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_VIDEO_DOWNLOAD,
					},
					{
						description: this.$t
							? this.$t('upgrade_modal.features.ai_usage', {
									number: USAGE_LIMIT.AI_USAGE.ELITE === null ? this.$t('upgrade_modal.features.unlimited') : USAGE_LIMIT.AI_USAGE.ELITE,
							  })
							: `${USAGE_LIMIT.AI_USAGE.ELITE === null ? 'Unlimited' : USAGE_LIMIT.AI_USAGE.ELITE} AI generations per month`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.AI_STYLIST_EXCEED_LIMIT,
						hiddenByDefault: true,
					},
					{
						description: this.$t ? this.$t('upgrade_modal.features.exclusive_materials') : `Exclusive presets & materials`,
						featureType: null,
					},
					{
						description: this.$t ? this.$t('upgrade_modal.features.exclusive_character_poses') : `Exclusive character poses`,
						featureType: null,
					},
					{
						description: this.$t ? this.$t('upgrade_modal.features.commercial_license') : `Commercial license`,
						featureType: null,
					},
					{
						description: this.$t ? this.$t('upgrade_modal.features.file_3d_export') : `3D file exports`,
						featureType: UPGRADE_PLAN_TRIGGER_TYPE.GLB_DOWNLOAD,
					},
				],
			};
		}

		get planPeriodOptions() {
			return [
				{
					label: this.getPricingForUser.PRO_MONTH
						? this.$t
							? this.$t('page.upgrade_plan.monthly')
							: 'Monthly'
						: this.$t
						? this.$t('page.upgrade_plan.quarterly')
						: 'Quarterly',
					description: null,
					value: true,
				},
				{
					label: this.$t ? this.$t('page.upgrade_plan.yearly') : 'Yearly',
					description: '-33%',
					value: false,
				},
			];
		}

		get planOptions() {
			return [
				{
					label: this.$t ? this.$t('page.upgrade_plan.pro.pro') : 'Pro',
					value: true,
				},
				{
					label: this.$t ? this.$t('page.upgrade_plan.elite.elite') : 'Elite',
					value: false,
				},
			];
		}

		get planPrice() {
			const pricingBaseInfo = this.pricingBaseInfo;
			const pricing = this.pricing;
			if (this.isProPlanSelected) {
				return {
					price: pricingBaseInfo.pro.price,
					priceBeforeDiscount: pricingBaseInfo.pro.priceBeforeDiscount,
					billingText: pricingBaseInfo.pro.billingText,
					currency: pricing.currencyPrefix,
					currentPlan: pricingBaseInfo.pro.plan == this.subscription && this.subscriptionActive,
				};
			}
			return {
				price: pricingBaseInfo.elite.price,
				priceBeforeDiscount: pricingBaseInfo.elite.priceBeforeDiscount,
				billingText: pricingBaseInfo.elite.billingText,
				currency: pricing.currencyPrefix,
				currentPlan: pricingBaseInfo.elite.plan == this.subscription && this.subscriptionActive,
			};
		}

		get features() {
			const _features: any[] = this.isProPlanSelected
				? this.UPGRADE_FEATURE_SERVICES[SUBSCRIPTION_TYPE.PROFESSIONAL]
				: this.UPGRADE_FEATURE_SERVICES[SUBSCRIPTION_TYPE.ELITE];
			const triggerType = this.upgradeModalState.triggerType;
			const features = [];
			for (let i = 0; i < _features.length; i++) {
				const feature = _features[i];
				const isActive = feature.featureType && (feature.featureType == triggerType || feature.featureType.includes(triggerType));
				const isHidden = feature.hiddenByDefault;
				if (isActive && !isHidden) {
					features.push({
						...feature,
						active: true,
						order: i > 1 ? 1 : i,
					});
					if (i > 1) features[1].order = i;
				} else if (!isHidden) {
					features.push({
						...feature,
						active: false,
						order: i,
					});
				}
			}

			const activeButHiddenByDefaultFeature = _features.find((feature) => {
				const isActive = feature.featureType && (feature.featureType == triggerType || feature.featureType.includes(triggerType));
				const isHidden = feature.hiddenByDefault;
				return isActive && isHidden;
			});
			if (activeButHiddenByDefaultFeature) {
				features[1] = { ...activeButHiddenByDefaultFeature, active: true, order: 1 };
			}

			return features;
		}

		get isEliteFeature() {
			return this.upgradeModalState.triggerType === UPGRADE_PLAN_TRIGGER_TYPE.GLB_DOWNLOAD;
		}

		get isClosable() {
			return this.upgradeModalState.triggerType !== UPGRADE_PLAN_TRIGGER_TYPE.ACCESS_PREMIUM_PROJECT;
		}

		get shouldDisableProPlan() {
			if (this.isEliteFeature) return true;
			if (
				[
					UPGRADE_PLAN_TRIGGER_TYPE.PRO_GET_MORE_LIMITS,
					UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_IMAGE_DOWNLOAD,
					UPGRADE_PLAN_TRIGGER_TYPE.LIMIT_USAGE_HQ_VIDEO_DOWNLOAD,
				].includes(this.upgradeModalState.triggerType) &&
				[SUBSCRIPTION.PRO_MONTHLY, SUBSCRIPTION.PRO_QUARTERLY, SUBSCRIPTION.PRO_ANNUALLY].includes(this.subscription)
			)
				return true;
			return false;
		}

		get tooltip() {
			if (this.isEliteFeature) return this.$t ? this.$t('upgrade_modal.tooltip.elite_feature') : 'This feature is not available for PRO';
			if (this.shouldDisableProPlan) return this.$t ? this.$t('upgrade_modal.tooltip.current_plan') : 'This is your current plan';
			return null;
		}

		get activePlanText() {
			return this.isProPlanSelected
				? this.$t
					? this.$t('page.upgrade_plan.pro.pro')
					: 'Pro'
				: this.$t
				? this.$t('page.upgrade_plan.elite.elite')
				: 'Elite';
		}

		get title() {
			const triggerCase = this.upgradeModalState.triggerCase;
			switch (triggerCase) {
				case SUBSCRIPTION_UPGRADE_CASE.DOWNLOAD_GLB:
					return this.$t
						? this.$t('upgrade_modal.title.download_glb', { plan: this.activePlanText })
						: `Download GLB with <br> <span class="text-color-primary-5">Pixcap ${this.activePlanText}</span> ✨`;
				case SUBSCRIPTION_UPGRADE_CASE.GET_MORE_AI_USAGE:
					return this.$t
						? this.$t('upgrade_modal.title.get_more_ai_usage', {
								count_prefix: this.isProPlanSelected ? this.$t('upgrade_modal.more') : this.$t('upgrade_modal.unlimited'),
								plan: this.activePlanText,
						  })
						: `Get ${this.isProPlanSelected ? 'more' : 'unlimited'} AI generations with <span class="text-color-primary-5">Pixcap ${
								this.activePlanText
						  }</span> ✨`;
				case SUBSCRIPTION_UPGRADE_CASE.GET_MORE_HQ_VIDEO_DOWNLOAD:
					return this.$t ? this.$t('upgrade_modal.title.get_more_hq_video_download') : `Get more video downloads <br> that suit your needs.`;
				case SUBSCRIPTION_UPGRADE_CASE.ACCESS_PREMIUM_PROJECT:
					return this.$t
						? this.$t('upgrade_modal.title.access_premium_project', { plan: this.activePlanText })
						: `Edit your premium designs <br> with <span class="text-color-primary-5">Pixcap ${this.activePlanText}</span> ✨`;
				case SUBSCRIPTION_UPGRADE_CASE.USE_REMOVE_WATERMARK:
					return this.$t
						? this.$t('upgrade_modal.title.remove_watermark', { plan: this.activePlanText })
						: `Remove watermark with <br> <span class="text-color-primary-5">Pixcap ${this.activePlanText}</span> ✨`;
				//   Usage limit
				case SUBSCRIPTION_UPGRADE_CASE.HIT_USAGE_LIMIT_AI:
					return this.$t
						? this.$t('upgrade_modal.title.hit_usage_limit_ai', {
								number: this.isProPlanSelected ? USAGE_LIMIT.AI_USAGE.FREE : USAGE_LIMIT.AI_USAGE.PRO,
								plan: this.activePlanText,
								count_prefix: this.isProPlanSelected ? this.$t('upgrade_modal.more') : this.$t('upgrade_modal.unlimited'),
						  })
						: `Monthly limit reached (${
								this.isProPlanSelected ? USAGE_LIMIT.AI_USAGE.FREE : USAGE_LIMIT.AI_USAGE.PRO
						  }). <br> Upgrade to <span class="text-color-primary-5">Pixcap ${this.activePlanText}</span> ✨ <br>  for ${
								this.isProPlanSelected ? 'more' : 'unlimited'
						  } AI generations.`;
				case SUBSCRIPTION_UPGRADE_CASE.HIT_USAGE_LIMIT_DEFAULT_DOWNLOAD:
					return this.$t
						? this.$t('upgrade_modal.title.hit_usage_limit_default_download', {
								number: USAGE_LIMIT.DEFAULT_DOWNLOAD.FREE,
								plan: this.activePlanText,
						  })
						: `Daily limit reached (${USAGE_LIMIT.DEFAULT_DOWNLOAD.FREE}). <br> Upgrade to <span class="text-color-primary-5">Pixcap ${this.activePlanText}</span> ✨ <br>  for unlimited downloads.`;
				case SUBSCRIPTION_UPGRADE_CASE.HIT_USAGE_LIMIT_HQ_IMAGE_DOWNLOAD:
					return this.$t
						? this.$t('upgrade_modal.title.hit_usage_limit_hq_image_download', {
								number: this.isProPlanSelected ? USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.FREE : USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.PRO,
								plan: this.activePlanText,
								count_prefix: this.isProPlanSelected ? this.$t('upgrade_modal.more') : this.$t('upgrade_modal.unlimited'),
						  })
						: `Monthly limit reached (${
								this.isProPlanSelected ? USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.FREE : USAGE_LIMIT.HQ_IMAGE_DOWNLOAD.PRO
						  }). <br> Upgrade to <span class="text-color-primary-5">Pixcap ${this.activePlanText}</span> ✨ <br>  for ${
								this.isProPlanSelected ? 'more' : 'unlimited'
						  } downloads.`;
				case SUBSCRIPTION_UPGRADE_CASE.PRO_TO_ELITE:
					return this.$t
						? this.$t('upgrade_modal.title.pro_to_elite')
						: `Upgrade your account <br> to <span class="text-color-primary-5">Pixcap Elite</span> ✨`;
				case SUBSCRIPTION_UPGRADE_CASE.USE_PRO_ITEM:
				default:
					return this.$t
						? this.$t('upgrade_modal.title.use_pro_item', { plan: this.activePlanText })
						: `Upgrade your account <br> to <span class="text-color-primary-5">Pixcap ${this.activePlanText}</span> ✨`;
			}
		}

		handleUpgradePlan() {
			if (this.isProPlanSelected) this.handleGetPro();
			else this.handleGetElite();
		}

		handleCloseModal() {
			if (this.isClosable) {
				UserMutations.setUpgradeModalState(this.$store, null);
			}
		}

		handleDefaultPlan() {
			this.isProPlanSelected = this.upgradeModalState.initPlan == SUBSCRIPTION_TYPE.ELITE ? false : !this.isProUser;
		}

		created() {
			this.handleDefaultPlan();
			this.$pixcap.$mixpanel.track('Open Upgrade Member Modal', { 'Trigger Reason': this.upgradeModalState.triggerCase });
		}
	}
