export enum CornerDirection {
	TOP_LEFT = 'top-left',
	TOP_RIGHT = 'top-right',
	BOTTOM_RIGHT = 'bottom-right',
	BOTTOM_LEFT = 'bottom-left',
}

export enum EdgeDirection {
	LEFT = 'left',
	TOP = 'top',
	RIGHT = 'right',
	BOTTOM = 'bottom',
}

export type CornerHandler = {
	direction: CornerDirection;
	rotateCursor: string;
	resizeCursor: string;
};
export type EdgeHandler = {
	direction: EdgeDirection;
	cursor: string;
};
export const VERTICAL_EDGE_HANDLERS: EdgeHandler[] = [
	{
		direction: EdgeDirection.TOP,
		cursor: 'cursor-n-resize',
	},
	{
		direction: EdgeDirection.BOTTOM,
		cursor: 'cursor-n-resize',
	},
];
export const HORIZONTAL_EDGE_HANDLERS: EdgeHandler[] = [
	{
		direction: EdgeDirection.RIGHT,
		cursor: 'cursor-e-resize',
	},
	{
		direction: EdgeDirection.LEFT,
		cursor: 'cursor-e-resize',
	},
];
export const CORNER_HANDLERS: CornerHandler[] = [
	{
		direction: CornerDirection.TOP_LEFT,
		rotateCursor: 'cursor-315-rotate',
		resizeCursor: 'cursor-nw-resize',
	},
	{
		direction: CornerDirection.TOP_RIGHT,
		rotateCursor: 'cursor-45-rotate',
		resizeCursor: 'cursor-ne-resize',
	},
	{
		direction: CornerDirection.BOTTOM_RIGHT,
		rotateCursor: 'cursor-135-rotate',
		resizeCursor: 'cursor-nw-resize',
	},
	{
		direction: CornerDirection.BOTTOM_LEFT,
		rotateCursor: 'cursor-225-rotate',
		resizeCursor: 'cursor-ne-resize',
	},
];
export const DIRECTION_CURSOR_MAP = {};

CORNER_HANDLERS.forEach((_) => {
	DIRECTION_CURSOR_MAP[_.direction] = _.resizeCursor;
});
VERTICAL_EDGE_HANDLERS.forEach((_) => {
	DIRECTION_CURSOR_MAP[_.direction] = _.cursor;
});
HORIZONTAL_EDGE_HANDLERS.forEach((_) => {
	DIRECTION_CURSOR_MAP[_.direction] = _.cursor;
});
